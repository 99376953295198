.footer {
  margin-top: auto;
  padding: var(--spacing-xlarge) 0;
  position: relative;
}

.branding {
  align-items: center;
  display: flex;
  justify-content: center;
}

.title {
  color: rgb(var(--color-white));
  margin-right: var(--spacing-xsmall);
  opacity: 0.5;
}

.hypermint {
  width: 115px;
  object-fit: contain;
}
