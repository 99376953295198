.connectedWallet {
  align-items: center;
  display: flex;
  justify-content: center;

  &:hover {
    > .disconnectWallet {
      display: flex;
    }

    > .disconnectButton {
      display: none;
    }
  }
}

.disconnectWallet {
  align-items: center;
  background-color: rgb(var(--color-state-error));
  border-radius: var(--border-radius-regular);
  color: rgb(var(--color-black));
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: var(--spacing-xxsmall) var(--spacing-xsmall);
  display: none;
}

.walletIcon {
  height: 20px;
  margin-right: var(--spacing-xsmall);
  width: 20px;
}

.walletText {
  font-weight: var(--font-weight-semiBold);
}

.connectWalletText {
  color: rgb(var(--color-white));
  margin-left: var(--spacing-small);

  @media screen and (min-width: 768px) {
    &::after {
      content: " Wallet";
    }
  }
}

.connectedWalletMeta {
  color: rgb(var(--color-white));
  margin: 0 var(--spacing-xxsmall);
  padding-right: var(--spacing-xsmall);
  line-height: 1;
}

.withSpacer {
  border-right: 1px solid rgb(var(--color-white));
}

.wrapperButton {
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
}
