.card {
  border-radius: var(--border-radius-regular);
  border: 1px solid rgba(var(--color-primary), 0.3);
  overflow: hidden;
  padding: var(--spacing-xlarge);
  position: relative;

  &::before {
    backdrop-filter: blur(24px);
    background: rgba(255, 255, 255, 0.06);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.cardInner {
  position: relative;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-large);
  position: relative;
}

.title {
  color: rgb(var(--color-white));
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-semiBold);
  margin: 0;
}

.imageWrap {
  position: relative;
}

.remainingCounter {
  background: rgba(var(--color-black), 0.3);
  border-radius: var(--border-radius-regular);
  bottom: var(--spacing-small);
  color: rgb(var(--color-white));
  padding: var(--spacing-xsmall);
  position: absolute;
  right: var(--spacing-small);
}

.image {
  border-radius: var(--border-radius-regular);
  // height: 300px;
  object-fit: cover;
  width: 100%;
}

.form {
  margin-top: var(--spacing-large);
}

.inputWrap {
  display: flex;
  margin-bottom: var(--spacing-xxlarge);
  position: relative;
}

.input {
  appearance: none;
  background-color: rgba(var(--color-dark-grey), 0.12);
  border-radius: var(--border-radius-regular);
  border: 1px solid rgba(var(--color-dark-grey), 0.12);
  color: rgb(var(--color-white));
  flex-grow: 1;
  padding: var(--spacing-regular) var(--spacing-small);

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.inputWithError {
  border: 1px solid rgb(var(--color-state-error));
  outline: rgb(var(--color-state-error));
}

.inputError {
  position: absolute;
  top: 100%;
  margin-top: var(--spacing-xxsmall);
  font-size: var(--font-size-h3);
  color: rgb(var(--color-state-error));
}

.inputHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-small);
}

.inputContent {
  color: rgb(var(--color-white));
}

.quantityInput {
  opacity: 1;
}

.breakdownShowing {
  opacity: 0;
}

.infoIcon {
  cursor: pointer;
  height: 20px;
  width: 20px;

  > img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}

.modalHeader {
  margin: 0;
}

.modalDescription {
  color: rgb(var(--color-light-grey));
  line-height: 1.6;
}

.purchaseButtons {
  display: grid;
  gap: var(--spacing-regular);
}
