.container {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1200px;
  padding: var(--spacing-large);
  width: 100%;
}

.narrowContainer {
  max-width: 1100px;
}

.wideContainer {
  max-width: 1600px;
}
