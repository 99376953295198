.button {
  appearance: none;
  background-color: rgb(var(--color-primary));
  border: 0;
  border-radius: var(--border-radius-large);
  color: rgb(var(--color-white));
  cursor: pointer;
  font-family: Luna;
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-semiBold);
  min-height: 50px;
  padding: var(--spacing-small);
  width: 100%;

  &:disabled {
    background-color: rgb(var(--color-dark-grey-disabled));
    color: rgb(var(--color-light-grey-disabled));
    cursor: not-allowed;
  }
}

.soldOutButton {
  cursor: not-allowed;
}
