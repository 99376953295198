.header {
  background-color: rgb(var(--color-black));
  position: sticky;
  top: 0;
  z-index: 1;
}

.headerContainer {
  display: grid;
  grid-template-areas:
    "logo wallet"
    "counters counters";
  padding: var(--spacing-large);
  gap: var(--spacing-large);

  @media screen and (min-width: 1024px) {
    grid-template-areas: "logo wallet counters";
  }
}

.logo {
  grid-area: logo;
  display: flex;
  align-items: center;

  @media screen and (min-width: 1024px) {
    margin: 0;
  }
}

.timers {
  grid-area: counters;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (min-width: 1024px) {
    justify-content: flex-end;
  }
}

.connectedWallet {
  grid-area: wallet;
  display: flex;
  justify-content: flex-end;

  @media screen and (min-width: 1024px) {
    justify-content: center;
  }
}

.countdown {
  display: flex;
  border-radius: var(--border-radius-regular);
  background-color: rgba(255, 255, 255, 0.08);
  padding: var(--spacing-xsmall) var(--spacing-regular);
}

.countdownLive {
  background-color: rgba(var(--color-state-success), 0.2);
  border: 1px solid rgba(var(--color-state-success), 0.4);
}

.countdownTitle {
  color: rgba(var(--color-white), 0.8);
  text-transform: uppercase;
  font-family: var(--font-family-secondary);
}

.contentEmphasis {
  color: rgb(var(--color-white));
  font-weight: var(--font-weight-bold);
}
