.wrapper {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.heading {
  color: rgb(var(--color-white));
  margin-top: var(--spacing-xxsmall);
  text-transform: uppercase;
}

.subHeading {
  color: rgb(var(--color-light-grey));
  text-transform: uppercase;
  margin-top: 0;
}

.description {
  color: rgb(var(--color-off-white));
  line-height: 1.6;
}

p.externalUrl {
  margin-top: var(--spacing-xxlarge);
  a {
    color: rgb(var(--color-white));
    :visited {
      color: rgb(var(--color-off-white));
    }
  }
}

.expander {
  appearance: none;
  background-color: transparent;
  border: 0;
  color: rgb(var(--color-white));
  cursor: pointer;
  display: block;
  font-weight: var(--font-weight-bold);
  margin-top: var(--spacing-large);
}

.modalHeader {
  margin: 0;
}

.modalDescription {
  color: rgb(var(--color-light-grey));
  line-height: 1.6;
}
