.wrap {
  align-items: center;
  background-color: rgb(var(--color-black));
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  opacity: 1;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.25s ease-out 0.5s;
  visibility: visible;
  z-index: 99999;
}

.wrapLoaded {
  opacity: 0;
  visibility: hidden;
}

.loadingSpinner {
  border: 3px solid rgb(var(--color-primary));
  border-bottom-color: transparent;
  height: 40px;
  margin-bottom: var(--spacing-xxlarge);
  width: 40px;
}

.text {
  color: rgb(var(--color-white));
  margin-top: var(--spacing-large);
}
